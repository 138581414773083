import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import PageBanner from 'layouts/PageBanner.jsx'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, login, register, verify_contact_details } from 'utils'


const Login = () => {
    // const navigate = useNavigate();
    const [active, setActive] = useState('login');
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null);
    const [mobile, setMobile] = useState('');
    const [msg, setMsg] = useState('');
    const [errors, setErrors] = useState([]);
    const validate = () => {
        let errs = [];
        if (!email) {
            errs.push({ path: "email", message: "Email is Required." })
        }
        if (active == "register") {
            if (!mobile) {
                errs.push({ path: "mobile", message: "Mobile is Required." })
            }
        }
        if (!password) {
            errs.push({ path: "password", message: "Password is Required." })
        }
        setErrors(errs)
        if (errs.length > 0) {
            return false;
        }
        return true;
    }
    const makeAction = async (action) => {
        if (!validate()) {
            return false;
        } else {
            if (action == "login") {

                const itm = await axios.post(BASE_URL + "/api/app/users/web-login", {
                    email: email,
                    password: password
                });
                const res = itm.data.recordList[0];

                if (res) {
                    const token = res.token;
                    const uid = res.id;

                    if (res) {
                        localStorage.setItem('mtoken', token);
                        localStorage.setItem('muid', uid);
                    } else {
                        localStorage.clear();
                    }
                    return window.location.href = "/dashboard";
                }


            }
            if (action == "register") {
                if (!validate()) {
                    return false;
                }
                const res = await verify_contact_details(email, mobile);
                if (res.message.includes("Already Exist")) {
                    setMsg('Already Exits.')
                } else {
                    const register_res = await register(email, password, mobile);
                    const token = register_res.recordList[0].token;
                    const uid = register_res.recordList[0].id;
                    if (register_res && register_res.recordList && register_res.recordList.length > 0) {
                        localStorage.setItem('mtoken', token);
                        localStorage.setItem('muid', uid);
                        return window.location.href = "/dashboard";
                    } else {
                        localStorage.clear();
                    }
                }
            }
        }

    }


    return (
        <>
            <PageBanner title="Login" />
            <section className="space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="w-100 loginForm">
                                <div className="d-flex mb-5 justify-content-center gap-2">
                                    <button onClick={() => setActive('login')} className={`btn ${active == "login" ? 'btn-warning' : 'btn-outline-warning'}  px-5 rounded-pill `}>Login</button>
                                    <button onClick={() => setActive('register')} className={`btn ${active == "register" ? 'btn-warning' : 'btn-outline-warning'} px-5 rounded-pill`}>Register</button>
                                </div>
                                <div className="row form-group">
                                    <div className="col-md-4 text-end">
                                        <label htmlFor="">Enter Email</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" value={email} placeholder='Enter email' onChange={(event) => setEmail(event.target.value)} className="form-control" />
                                        <span className="text-danger">
                                            {errors.find(obj => obj.path == "email")?.message}
                                        </span>
                                    </div>
                                </div>
                                {
                                    active == "register" && (
                                        <>
                                            <div className="row form-group">
                                                <div className="col-md-4 text-end">
                                                    <label htmlFor="">Enter Mobile Number</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <div className="d-flex">
                                                        <span className="input-group-text bg-odd border border-theme rounded-0 me-2">+91</span>
                                                        <input placeholder='Enter Mobile Number' onChange={(event) => setMobile(event.target.value)} type="text" name="mobile" id="" className="form-control" />
                                                    </div>
                                                    <span className="text-danger">
                                                        {errors.find(obj => obj.path == "mobile")?.message}
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                <div className="row form-group">
                                    <div className="col-md-4 text-end">
                                        <label htmlFor="">Enter Password</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="d-flex position-relative">
                                            <input type="text" value={password} onChange={(event) => setPassword(event.target.value)} placeholder='Enter password' name="" id="" className="form-control" />
                                            <button className={`btn p-0 eyeicon`}>
                                                <FontAwesomeIcon icon={faEye} />
                                            </button>
                                        </div>
                                        <span className="text-danger">
                                            {errors.find(obj => obj.path == "password")?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className="row form-group">
                                    <div className="col-md-4 text-end"></div>
                                    <div className="col-md-8">

                                        <button onClick={() => makeAction(active)} className="w-100 btn-flower text-uppercase">
                                            {active}
                                        </button>
                                    </div>
                                </div>
                                <div className="row form-group mb-0">
                                    <div className="col-md-8 offset-md-4">
                                        <p className="mb-0">
                                            By clicking register free, I agree to the T&C and Privacy Policy
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
