import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import PageBanner from 'layouts/PageBanner.jsx'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { getMasterData, search_profiles, url } from 'utils';
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

const SearchProfile = () => {
    const token = localStorage.getItem('mtoken');

    const [profiles, setProfiles] = useState([]);
    const [occs, setOccs] = useState([]);
    const [edus, setEdus] = useState([]);
    const [mats, setMats] = useState([]);
    const [gender, setGender] = useState('');
    const [name, setName] = useState('');
    const [data, setData] = useState({});

    const get_master_data = async () => {
        const res = await getMasterData();
        return setData(res.recordList);
    }
    const navigate = useNavigate();
    useEffect(() => {
        get_master_data();
    }, []);

    const getprofiles = async () => {
        const dobj = {
            "startIndex": 0,
            "fetchRecord": 10,
            "name": null,
            "gender": gender,
            "occupationId": occs,
            "educationId": edus,
            "maritalStatusId": mats,
            "cityName": null,
            "stateId": null,
            "age1": 19,
            "age2": 26,
            "height1": 4,
            "height2": 6
        }
        const res = await search_profiles(token, dobj);
        if (res.recordList) {
            setProfiles(res?.recordList)
        } else {
            navigate(-1)
        }

    }
    const addToOccupation = (e) => {
        let arr = [...occs];
        if (e.target.checked) {
            arr.push(Number(e.target.value))
        } else {
            const idx = arr.indexOf(e.target.value);
            if (idx > -1) {
                arr.splice(idx, 1);
            }
        }
        setOccs(arr);
    }
    const addToEduction = (e) => {
        let arr = [...edus];
        if (e.target.checked) {
            arr.push(Number(e.target.value))
        } else {
            const idx = arr.indexOf(e.target.value);
            if (idx > -1) {
                arr.splice(idx, 1);
            }
        }
        setEdus(arr)
    }
    const maritualStatus = (e) => {
        const val = e.target.value
        setMats([Number(val)])
    }


    return (
        <>
            <PageBanner title="Search Profiles" />
            <section className="py-4">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-md-2">
                            <div className="w-100">
                                <input type="text" name="name" onChange={(event) => setName(event.target.value)} placeholder='Enter Name' id="" className="form-control border-secondary" />
                            </div>
                        </div>

                        <div className="col-md-2  dropdown">
                            <div class="w-100">
                                <button class="btn w-100 border border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    Occupation
                                </button>
                                <div class="dropdown-menu p-2 w-100" >
                                    <ul className="list-unstyled" style={{ height: '350px', overflowY: 'auto' }}>
                                        <PerfectScrollbar>
                                            {
                                                data?.occupation && data?.occupation.map((oc) => (
                                                    <>
                                                        <li className='mb-1'>
                                                            <label htmlFor={`labeleducation${oc.id}`}>
                                                                <input onClick={addToOccupation} type="checkbox" value={oc.id} id={`labeleducation${oc.id}`} className="form-switch me-2" />
                                                                {oc.name}
                                                            </label>
                                                        </li>
                                                    </>
                                                ))
                                            }
                                        </PerfectScrollbar>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 position-relative">
                            <div class="w-100">
                                <button class="btn w-100 border border-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                    Education
                                </button>
                                <ul class="dropdown-menu p-2 w-100">
                                    {
                                        data?.education && data?.education.map((oc) => (
                                            <>
                                                <li className='mb-1'>
                                                    <label htmlFor={`labeleducation${oc.id}`}>
                                                        <input onClick={addToEduction} type="checkbox" value={oc.id} id={`labeleducation${oc.id}`} className="form-switch me-2" />
                                                        {oc.name}
                                                    </label>
                                                </li>
                                            </>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <select name="maritalStatusId" onChange={maritualStatus} id="maritalStatusId" className="form-select border-secondary">
                                <option value="">---Select---</option>
                                {
                                    data?.maritalStatus && data?.maritalStatus.map((relg) => (
                                        <option value={relg.id}>{relg.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select name="gender" onClick={(event) => setGender(event.target.value)} id="gender" className="form-select border-secondary">
                                <option value="">---Select---</option>
                                <option value="Male" >Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                        <div className="col-md-2">
                            <button onClick={getprofiles} className="btn btn-flower">
                                <FilterOutlined /> Filter
                            </button>
                        </div>

                    </div>
                    {
                        profiles.length > 0 && (
                            <>

                                <div className="row">
                                    {
                                        profiles.map((pr) => (
                                            <>
                                                <div className="col-md-4 mb-4">
                                                    <div className="w-100 profile__box p-2 position-relative">
                                                        <div className="d-flex gap-2 mb-0">
                                                            <figure className='inline-block mb-0'>
                                                                <img src={url + '/' + pr.imageUrl} alt="" className="img-fluid w-100" />
                                                            </figure>
                                                            <div className="inline-block mb-0 desc__box px-2">
                                                                <h4>
                                                                    {pr?.firstName + ' ' + pr?.lastName}
                                                                </h4>
                                                                <p className='education'>
                                                                    {pr?.occupation}
                                                                </p>

                                                                <p className="location">
                                                                    {pr?.gender}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <Link to={'/profile/' + pr.id} className='position-absolute end-0 p-2 text-danger top-50 translate-middle-y'>
                                                            <EyeOutlined />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </>
                                        ))
                                    }

                                </div>

                            </>
                        )
                    }
                    {
                        profiles.length == 0 && (
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="alert alert-danger">
                                            No Record Available !
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </section>

        </>
    )
}

export default SearchProfile
