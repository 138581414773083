import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import facebook from 'assets/images/facebook.png'
import twitter from 'assets/images/twitter.png'
import instagram from 'assets/images/instagram.png'
import youtube from 'assets/images/youtube.png'
import logo from 'assets/images/logo.png'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {

    const location = useLocation()

    const privacy = location.pathname == '/privacy-policy'
    const refund = location.pathname == '/refund-cancellation'
    const terms = location.pathname == '/terms-conditions'




    return (
        <>
            <footer className='space'>
                <div className="container">
                    <div className="row no-gutters">
                        {(!privacy && !refund && !terms) && <>
                            <div className="col-xxl-3 col-lg-3">
                                <div className="row">
                                    <div className="col-xxl-12 col-sm-12">
                                        <div className="footer-widgets">
                                            <div className="logimg mb-4">
                                                <img src={logo} width={90} alt="" className="img-fluid" />
                                            </div>
                                            <p className="footer-text">
                                                Connecting people with precision and care. We strive to build meaningful relationships through trust and dedication, ensuring a seamless and fulfilling experience.


                                            </p>
                                            {/* <div className="social-icons">
                                                <ul className="list-unstyled d-flex gap-2 social_apps">
                                                    <li>
                                                        <Link to={'/'}>
                                                            <img src={facebook} alt="" className="img-fluid" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'/'}>
                                                            <img src={twitter} alt="" className="img-fluid" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'/'}>
                                                            <img src={instagram} alt="" className="img-fluid" />
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to={'/'}>
                                                            <img src={youtube} alt="" className="img-fluid" />
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div> */}
                                            <div className="footer-widget-contact d-none">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <div><i className="bi bi-geo-alt"></i></div>
                                                        <div>Envato Pty Ltd, 13/2 Elizabeth St Melbourne VIC 3000, Australia</div>
                                                    </li>
                                                    <li>
                                                        <div><i className="bi bi-phone"></i></div>
                                                        <div><a href="tel:+1234567899">(+01) 123 456 7890</a></div>
                                                    </li>
                                                    <li>
                                                        <div><i className="bi bi-envelope"></i></div>
                                                        <div><a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-xxl-9 col-lg-9 mr-top-footer">
                                <div className="row">
                                    <div className="col-md-3 col-sm-4 col-12">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">Support</h4>
                                            <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                <li><a href="javascript:">Getting started</a></li>
                                                <li><a href="javascript:">Chat our support</a></li>
                                                <li><a href="javascript:">Help center</a></li>
                                                <li><a href="javascript:">Services status</a></li>
                                                <li><a href="javascript:">Report a bug</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4 col-12">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">Services</h4>
                                            <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                <li><a href="javascript:">Pricing</a></li>
                                                <li><a href="javascript:">Support</a></li>
                                                <li><a href="javascript:">Sales and Refunds</a></li>
                                                <li><a href="javascript:">Legal</a></li>
                                                <li><a href="javascript:">Testimonials &amp; Faq’s</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-4 col-12">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">Policy</h4>
                                            <ul className="list-unstyled icons-listing mb-0 widget-listing">
                                                <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                                                <li><Link to={'/terms-conditions'}>Terms & Conditions</Link></li>
                                                <li><Link to={'/refund-cancellation'}> Refunds & Cancellations</Link></li>
                                                <li><Link to={'/delete-policy'}> Delete Policy</Link></li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-3 ol-12">
                                        <div className="footer-widget">
                                            <h4 className="widget-title">Contact Us</h4>
                                            <div className="footer-widget-contact">
                                                <ul className="list-unstyled">
                                                    <li>

                                                        <p>
                                                            <FontAwesomeIcon icon={faLocationDot} />
                                                            Surajmal Memorial Education Society, C-4, Janakpuri, New Delhi-110058
                                                        </p>
                                                    </li>
                                                    <li>
                                                        <div><i className="bi bi-phone"></i></div>
                                                        <div><a href="tel:+1234567899">011-45656183</a></div>
                                                    </li>
                                                    <li>
                                                        <div><i className="bi bi-envelope"></i></div>
                                                        <div><a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </>}
                        <div className="col-md-12">
                            <div className="footer-text">
                                <p>
                                    <span className='fw-bold text-dark'>Disclaimer</span> :
                                    It has come to our attention that fraudulent activities have been discovered involving individuals pretending to be employees or authorized representatives of Suraj Sujan Matrimony to defraud our customers. Please note that the only legitimate domain name for Suraj Sujan Matrimony is www.surajsujanmatrimony.in. We urge you to verify any matrimonial opportunities related to Suraj Sujan Matrimony by reaching out to us through the contact details provided below. We also request that you do not respond with any personal information if you are uncertain about the communication and refrain from sending any money to third parties until you have verified the information with us. For any inquiries or to report suspicious behavior, please contact us at support@surajsujanmatrimony.in.
                                </p>



                            </div>
                        </div>
                    </div>
                    <div className="tiny-footer mt-3 border-top border-danger pt-4">
                        <div className="row align-items-center">
                            <div className="col-md-12 mb-0 text-center">Copyright © <span id="yearText">2024</span> <strong>Serenite</strong> Theme
                                All rights reserved.</div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="w-100 bg-dark text-white text-center p-2">
                <p className="mb-0 infotext">
                    This website is strictly for matrimonial purpose only and not a dating website.
                    Copyright © 2024. All rights reserved.
                </p>
            </div>
        </>
    )
}

export default Footer
