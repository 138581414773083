import { faEnvelope, faLocation, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PageBanner from 'layouts/PageBanner.jsx'
import world from 'assets/images/world.png'
import React from 'react'

const Contact = () => {
    return (
        <>
            <PageBanner title="Contact Us" />
            <section className="contact-info space">
                <div className="container">
                    <div className="row g-3 g-md-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="box d-flex align-items-center  h-100 ">

                                <div className="icon-box">
                                    <FontAwesomeIcon icon={faLocation} />
                                </div>

                                <div className="text-box">
                                    <h4>Address</h4>

                                    <p>7 Green Lake Street&nbsp;<span>Crawfordsville, IN 47933</span></p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box d-flex align-items-center  h-100 ">

                                <div className="icon-box">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>

                                <div className="text-box">
                                    <h4>Mobile</h4>

                                    <p>
                                        +91-9084694411
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="box d-flex align-items-center h-100 ">

                                <div className="icon-box">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>

                                <div className="text-box">
                                    <h4>Email</h4>

                                    <p>
                                        info@gmail.com
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            <section className="space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="w-100">
                                <img src={world} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="w-100 contact-form ">
                                <div className="row g-3 g-md-4 ">
                                    <div className="col-12">
                                        <input type="text" name="name" value="" className="form-control" placeholder="Your name"/>
                                    </div>
                                    <div className="col-12">
                                        <input type="email" name="email" value="" className="form-control" placeholder="Email Address"/>
                                    </div>
                                    <div className="col-12">
                                        <input type="text" name="subject" value="" placeholder="Subject" className="form-control"/>
                                    </div>
                                    <div className="col-12">
                                        <textarea className="form-control" cols="30" rows="10" name="message" placeholder="Message"></textarea>

                                    </div>
                                    <div className="col-12"> 
                                        <button className="btn-flower w-100">submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact
