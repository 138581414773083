import PageBanner from 'layouts/PageBanner.jsx'
import React, { useEffect, useState } from 'react'
import { get_all_profiles, get_most_viewed_profiles, get_nearest_profiles, send_proposel, url } from 'utils'
import { HeartOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'


const Profiles = () => {
    const token = localStorage.getItem('mtoken');
    var settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,

                }
            }
        ]
    };

    const [profiles, setProfiles] = useState([]);
    const [nprofiles, setNProfiles] = useState([]);
    const [mprofiles, setMProfiles] = useState([]);
    const getprofiles = async () => {
        const res = await get_all_profiles(token);
        setProfiles(res.recordList)
    }
    const getNprofiles = async () => {
        const res = await get_nearest_profiles(token);
        setNProfiles(res.recordList)
    }
    const getmprofiles = async () => {
        const res = await get_most_viewed_profiles(token);
        setMProfiles(res.recordList)
    }
    const sendpropos = async (id) => {
        const res = await send_proposel(id, token);
        if (res) {
            getprofiles();
            getmprofiles()
            getNprofiles();
        }
    }
    useEffect(() => {
        getprofiles();
        getNprofiles();
    }, []);
    return (
        <>
            <PageBanner title="Profiles" />
            <section className="py-4">
                <div className="container">
                    <div className="row mb-5 pb-2">
                        <div className="col-md-12">
                            <h4>Latest Profiles</h4>
                            <Slider {...settings}>

                                {
                                    profiles.map((pr) => (
                                        <>
                                            <div className="p-2">
                                                <div className="w-100 profile__box p-2">
                                                    <div className="d-flex gap-2">
                                                        <figure className='inline-block'>
                                                            <img src={url + '/' + pr.imageUrl} alt="" className="img-fluid w-100" />
                                                        </figure>
                                                        <div className="inline-block desc__box px-2">
                                                            <h4>
                                                                {pr.firstName + ' ' + pr?.middleName + ' ' + pr?.lastName}
                                                            </h4>
                                                            <p className='education'>
                                                                {pr?.occupation}
                                                            </p>

                                                            <p className="location">
                                                                {pr?.gender}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="border-top pt-3 border-danger d-flex align-items-center justify-content-between">
                                                        <button className='btn border-0 heartbtn'>
                                                            <HeartOutlined />
                                                        </button>
                                                        <Link to={'/profile/' + pr.id} className=' text-decoration-none text-nowrap btn btn-outline-danger btn-sm rounded-pill'>
                                                            View Profile
                                                        </Link>

                                                        {
                                                            pr?.isProposed == 0 ? (
                                                                <>
                                                                    <button onClick={() => sendpropos(pr.id)} className="btn rounded-pill btn-flower py-1">
                                                                        Send Proposal
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className='badge bg-danger rounded-pill'>
                                                                        Sent Already
                                                                    </span>
                                                                </>
                                                            )
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </Slider>
                        </div>


                    </div>
                    <div className="row mb-5 pb-2">
                        <div className="col-md-12">
                            <h4>Nearest Profiles</h4>
                            <Slider {...settings}>

                                {
                                    nprofiles.map((pr) => (
                                        <>
                                            <div className="p-2">
                                                <div className="w-100 profile__box p-2">
                                                    <div className="d-flex gap-2">
                                                        <figure className='inline-block'>
                                                            <img src={url + '/' + pr.imageUrl} alt="" className="img-fluid w-100" />
                                                        </figure>
                                                        <div className="inline-block desc__box px-2">
                                                            <h4>
                                                                {pr.firstName + ' ' + pr?.middleName + ' ' + pr?.lastName}
                                                            </h4>
                                                            <p className='education'>
                                                                {pr?.occupation}
                                                            </p>

                                                            <p className="location">
                                                                {pr?.gender}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="border-top pt-3 border-danger d-flex align-items-center justify-content-between">
                                                        <button className='btn border-0 heartbtn'>
                                                            <HeartOutlined />
                                                        </button>
                                                        <Link to={'/profile/' + pr.id} className=' text-decoration-none text-nowrap btn btn-outline-danger btn-sm rounded-pill'>
                                                            View Profile
                                                        </Link>

                                                        {
                                                            pr?.isProposed == 0 ? (
                                                                <>
                                                                    <button onClick={() => sendpropos(pr.id)} className="btn rounded-pill btn-flower py-1">
                                                                        Send Proposal
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span className='badge bg-danger rounded-pill'>
                                                                        Sent Already
                                                                    </span>
                                                                </>
                                                            )
                                                        }

                                                    </div>

                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </Slider>
                        </div>


                    </div>
                    {
                        mprofiles.length > 0 && (
                            <>

                                <div className="row mb-5 pb-2">
                                    <div className="col-md-12">
                                        <h4>Most Viewed Profiles</h4>
                                        <Slider {...settings}>

                                            {
                                                mprofiles.map((pr) => (
                                                    <>
                                                        <div className="p-2">
                                                            <div className="w-100 profile__box p-2">
                                                                <div className="d-flex gap-2">
                                                                    <figure className='inline-block'>
                                                                        <img src={url + '/' + pr.imageUrl} alt="" className="img-fluid w-100" />
                                                                    </figure>
                                                                    <div className="inline-block desc__box px-2">
                                                                        <h4>
                                                                            {pr.firstName + ' ' + pr?.middleName + ' ' + pr?.lastName}
                                                                        </h4>
                                                                        <p className='education'>
                                                                            {pr?.occupation}
                                                                        </p>

                                                                        <p className="location">
                                                                            {pr?.gender}
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="border-top pt-3 border-danger d-flex align-items-center justify-content-between">
                                                                    <button className='btn border-0 heartbtn'>
                                                                        <HeartOutlined />
                                                                    </button>
                                                                    <Link to={'/profile/' + pr.id} className=' text-decoration-none text-nowrap btn btn-outline-danger btn-sm rounded-pill'>
                                                                        View Profile
                                                                    </Link>

                                                                    {
                                                                        pr?.isProposed == 0 ? (
                                                                            <>
                                                                                <button onClick={() => sendpropos(pr.id)} className="btn rounded-pill btn-flower py-1">
                                                                                    Send Proposal
                                                                                </button>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <span className='badge bg-danger rounded-pill'>
                                                                                    Sent Already
                                                                                </span>
                                                                            </>
                                                                        )
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </Slider>
                                    </div>


                                </div>

                            </>
                        )
                    }
                </div>
            </section>
        </>
    )
}

export default Profiles
