import { EyeOutlined, MessageOutlined } from '@ant-design/icons';
import PageBanner from 'layouts/PageBanner.jsx'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { get_my_proposels, url } from 'utils';

const Myproposels = () => {
    const token = localStorage.getItem('mtoken');

    const [profiles, setProfiles] = useState([]);
    const getprofiles = async () => {
        const res = await get_my_proposels(token);
        setProfiles(res.recordList)
    }
    useEffect(() => {
        getprofiles();
    }, []);
    return (
        <>
            <PageBanner title="Proposels To Me" />
            <section className="py-4">
                <div className="container">
                    <div className="row">

                        {
                          profiles.length > 0 &&  profiles.map((pr) => (
                                <>
                                    <div className="col-md-4 mb-4">
                                        <div className="w-100 profile__box p-2 position-relative">
                                            <div className="d-flex gap-2 mb-0">
                                                <figure className='inline-block mb-0'>
                                                    <img src={url + '/' + pr.imageUrl} alt="" className="img-fluid w-100" />
                                                </figure>
                                                <div className="inline-block mb-0 desc__box px-2">
                                                    <h4>
                                                        {pr?.firstName + ' ' + pr?.lastName}
                                                    </h4>
                                                    <p className='education'>
                                                        {pr?.occupation}
                                                    </p>

                                                    <p className="location">
                                                        {pr?.gender}
                                                    </p>
                                                </div>
                                            </div>
                                            <Link to={'/profile/' + pr.id} className='position-absolute end-0 p-2 text-danger top-50 translate-middle-y'>
                                                <EyeOutlined />
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                        {
                            profiles.length == 0 && (
                                <>
                                    <div className="col-md-12">
                                        <div className="alert alert-danger">
                                            No Record Available. <a className='text-danger' href="/profiles">Send Requests Now</a>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </section>
        </>
    )
}

export default Myproposels
