import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import playstore from 'assets/images/google-play.png'

const Banner = () => {
  return (
    <>
      <section id='bannerbg' className='position-relative'>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="w-100 suraj_signup p-lg-5 p-4">
                <div className="form-group">
                  <select name="" id="" className="form-control form-select">
                    <option value="">Matrimony profile for</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="">Enter Name</label>
                  <input type="text" name="nam" placeholder='Enter your name' id="name" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="">Enter Email</label>
                  <input type="text" name="nam" placeholder='Enter your email' id="name" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="">Enter Mobile</label>
                  <div className="d-flex gap-2">
                    <span className="input-group-text bg-odd  rounded-0 border border-theme">
                      +91
                    </span>
                    <input type="text" name="nam" placeholder='Enter your mobile' id="name" className="form-control" />
                  </div>

                </div>
                <div className="form-group">
                  <button className="btn btn-warning py-3 w-100 rounded-0">Submit</button>
                </div>
                <div className="form-group mb-0">
                  <p className="mb-0">
                    By clicking register free, I agree to the <Link to={'/'}>T&C</Link>  and <Link to={'/'}>Privacy Policy</Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="w-100 p-5 position-relative z-3 text-start suraj_banner_text">
                <h2>
                  Find your perfect soul Mate !
                </h2>
                <p>
                  While perfection is an unattainable ideal, in this imagined partnership, we would complement each other in a beautifully imperfect harmony, bound by the threads of mutual respect, admiration, and love.
                </p>
                <div className="w-100 mt-4">
                  <div className="d-flex gap-3">
                    <button className="btn btn-custom">
                      <div className="w-100 p-2">
                        <img src={playstore} alt="" className="img-fluid" />
                      </div>
                    </button>
                    <button className="btn btn-warning px-md-5 text-white">
                      Get Started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner
