import { EditFilled, EditOutlined } from '@ant-design/icons';
import PageBanner from 'layouts/PageBanner.jsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getMasterData, update_my_profile, view_user_details } from 'utils';

const EditProfile = () => {
    const mid = localStorage.getItem('muid');
    const [user, setUser] = useState([]);
    const [data, setData] = useState({});
    const [isdone, setDone] = useState(false);
    const myprofile = async () => {
        const res = await view_user_details(mid);
        console.log(res.recordList[0])
        setUser(res.recordList[0])
    }
    const get_master_data = async () => {
        const res = await getMasterData();
        return setData(res.recordList);
    }

    useEffect(() => {
        myprofile();
        get_master_data();
    }, []);
    const handleformdta = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        const arr = { ...user };
        arr[key] = value;
        setUser(arr);
    }
    const updateProile = async () => {
        const arr = { ...user };
        // let x = {"id":157,
        // "fcmToken":"cgZJ5xVg3KNtktBlzs6r3z:APA91bE_fvHpFP1sXL1Lbc6uHHaiuFAfLTcffe3j9kaTdcgcJlBmQ10_cRPWuP7o5OgiL5hVdJnuenzS8Ho25iivpFh3gSKM5EKLKhcTTndRlkz0AptbTcnm88hROuWcI3AuMKF-V55O",
        // "firstName":"SHaun","middleName":"K","lastName":"G","gender":"Male","email":"pulkesh111@gmail.com",
        // "contactNo":"","motherGotra":"Solanki","fatherGotra":"Thenua","gotraAvoided":"Sikarwar",
        // "birthDate":"2005-03-07T11:00:00.000Z","languages":"Hindi","eyeColor":"Black","expectation":"",
        // "aboutMe":"","companyName":"null","businessName":"null",
        // "imageUrl":null,"religion":"Hindu","maritalStatus":"Single","community":null,
        // "occupation":"Farmer","education":"BCA","subCommunity":null,
        // "annualIncome":"15 lakh - 20 lakh","diet":"Vegitarian","height":"5.04","addressLine1":"shahgangj","addressLine2":"shahgangj",
        // "pincode":"282010","cityId":null,"districtId":null,"stateId":null,"countryId":null,"cityName":"agra","stateName":"up",
        // "districtName":null,"countryName":null,"employmentType":null,"age":19,"isFavourite":0,"isProposed":0,
        // "isVerifiedProfile":false,"userDocuments":[],"userPackage":null,"totalView":0,"todayView":0}
        let jsonarr = {
            "id": arr.id,
            "firstName": arr.firstName,
            "email": arr.email,
            "lastName": arr.lastName,
            "gender": arr.gender,
            "contactNo": arr.contactNo,
            "flag": null,
            "countryCode": null,
            "imageUrl": null,
            "token": null,
            "refreshToken": null,
            "masterEntryData": {},
            "addressLine1": arr.addressLine1,
            "addressLine2": arr.addressLine2,
            "religion": arr.religion,
            "community": null,
            "subCommunity": null,
            "maritalStatus": arr.maritalStatus,
            "occupation": arr.occupation,
            "education": arr.education,
            "diet": arr.diet,
            "annualIncome": null,
            "height": arr.height,
            "expectation": arr.expectation,
            "birthDate": arr.birthDate,
            "languages": arr.languages,
            "eyeColor": "Black",
            "employmentType": arr.employmentType,
            "aboutMe": arr.aboutMe,
            "cityName": arr.cityName,
            "pincode": arr.pincode,
            "countryName": arr.countryName,
            "stateName": arr.stateName,
            "communityId": arr.communityId,
            "subCommunityId": arr.subCommunityId,
            "educationId": arr.educationId,
            "occupationId": arr.occupationId,
            "employmentTypeId": arr.employmentTypeId,
            "annualIncomeId": arr.annualIncomeId,
            "religionId": arr.religionId,
            "maritalStatusId": arr.maritalStatusId,
            "heightId": arr.heightId,
            "dietId": arr.dietId,
            "businessName": "null",
            "companyName": "null",
            "userFlags": null,
            "oAuthUserId": null,
            "oAuthUserName": null,
            "oAuthUserPicUrl": null,
            "oAuthAccessToken": null,
            "description": null,
            "oAuthProviderName": null,
            "isAppleLogin": false,
            "isOAuth": false,
            "latitude": null,
            "longitude": null,
            "userDocuments": [],
            "documents": [],
            "isSignup": false,
            "isVerifiedProfile": false,
            "userPackage": null,
            "isFavourite": false,
            "totalView": 0,
            "districtId": null,
            "stateId": null,
            "countryId": null,
            "districtName": null,
            "cityId": null,
            "motherGotra": arr.motherGotra,
            "fatherGotra": arr.fatherGotra,
            "gotraAvoided": "Sikarwar"
        }
        const res = await update_my_profile(jsonarr);
        if (res.status == "200") {
            setDone(true);
        }
    }
    return (
        <>
            <PageBanner title="My Profile" />
            <section className="space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100  edit__Profile">
                                <div className="comborder border__top"></div>
                                <div className="comborder border__bottom"></div>
                                <div className="comborder border__left"></div>
                                <div className="comborder border__right"></div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {
                                            isdone && (
                                                <>
                                                    <div className="alert alert-success">
                                                        Profile Updated Successfully
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 p-1 bg-gradient text-white">
                                            <EditOutlined />
                                            Personal Details</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    First Name
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex gap-2">
                                                    <input type="text" name="firstName" onChange={handleformdta} id="" value={user?.firstName} className="form-control" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Last Name
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex gap-2">
                                                    <input type="text" name="lastName" id="" onChange={handleformdta} value={user?.lastName} className="form-control" />

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Phone
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex gap-2">
                                                    <input type="text" name="contactNo" onChange={handleformdta} id="" value={user?.contactNo} className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Expectation
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex gap-2">
                                                    <input type="text" name="expectation" onChange={handleformdta} id="" value={user?.expectation} className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    About Me
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex gap-2">
                                                    <input type="text" name="aboutMe" onChange={handleformdta} id="" value={user?.aboutMe} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">Gender</label>
                                            </div>
                                            <div className="col-12">
                                                <select name="gender" onChange={handleformdta} id="gender" className="form-select">
                                                    <option value="">---Select---</option>
                                                    <option value="Male" selected={user?.gender == "Male" ? 'selected' : null}>Male</option>
                                                    <option value="Female" selected={user?.gender == "Female" ? 'selected' : null}>Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Height
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100 label_value">
                                                    <select name="heightId" id="heightId" onChange={handleformdta} className="form-select">
                                                        <option value="">---Select---</option>
                                                        {
                                                            data?.height && data?.height.map((relg) => (
                                                                <option value={relg.id} >{relg.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Religion
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100 label_value">
                                                    <select name="religionId" onChange={handleformdta} id="religionId" className="form-select">
                                                        <option value="">---Select---</option>
                                                        {
                                                            data?.religion && data?.religion.map((relg) => (
                                                                <option value={relg.id} >{relg.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Diet
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100 label_value">
                                                    <select name="dietId" id="dietId" onChange={handleformdta} className="form-select">
                                                        <option value="">---Select---</option>
                                                        {
                                                            data?.diet && data?.diet.map((relg) => (
                                                                <option value={relg.id}>{relg.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="w-100 p-1 bg-gradient text-white">
                                        <EditOutlined />
                                        Marital Details</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Community
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100 label_value">
                                                    <select name="communityId" id="communityId" onChange={handleformdta} className="form-select">
                                                        <option value="">---Select---</option>
                                                        {
                                                            data?.community && data?.community.map((relg) => (
                                                                <option value={relg.id} >{relg.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    sub Community
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <div className="w-100 label_value">
                                                    <select name="subCommunityId" id="subCommunityId" onChange={handleformdta} className="form-select">
                                                        <option value="">---Select---</option>
                                                        {
                                                            data?.subCommunity && data?.subCommunity.map((relg) => (
                                                                <option value={relg.id}>{relg.name}</option>
                                                            ))
                                                        }
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Father Gautra
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="fatherGotra" id="fatherGotra" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.gotra && data?.gotra.map((relg) => (
                                                            <option value={relg.name}>{relg.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Mother Gautra
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="motherGotra" id="motherGotra" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.gotra && data?.gotra.map((relg) => (
                                                            <option value={relg.name} >{relg.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                Marital Status
                                            </label>
                                        </div>
                                        <div className="col-12">

                                            <select name="maritalStatusId" id="maritalStatusId" onChange={handleformdta} className="form-select">
                                                <option value="">---Select---</option>
                                                {
                                                    data?.maritalStatus && data?.maritalStatus.map((relg) => (
                                                        <option value={relg.id}>{relg.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="w-100 p-1 bg-gradient text-white">
                                            <EditOutlined />
                                            Educational Details</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Age
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <select name="age" id="age" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.ageList && data?.ageList.map((ag) => (
                                                            <option value={ag} selected={user?.age == ag}>{ag}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Education
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="educationId" id="educationId" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.education && data?.education.map((relg) => (
                                                            <option value={relg.id}  >{relg.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Date of Birth
                                                </label>
                                            </div>
                                            <div className="col-12">
                                                <input type="date" name="birthDate" onChange={handleformdta} id="" value={moment(user?.birthDate).format('YYYY-MM-DD')} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Languages
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <input type="text" name="languages" onChange={handleformdta} value={user?.languages} id="" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="w-100 p-1 bg-gradient text-white">
                                            <EditOutlined />
                                            Occupation Details</div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Employment Type
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="employmentTypeId" id="employmentTypeId" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.employmentType && data?.employmentType.map((ag) => (
                                                            <option value={ag.id}>{ag.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    occupation
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="occupationId" id="occupationId" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.occupation && data?.occupation.map((ag) => (
                                                            <option value={ag.id}>{ag.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-12">
                                                <label htmlFor="">
                                                    Annual Income
                                                </label>
                                            </div>
                                            <div className="col-12">

                                                <select name="annualIncomeId" id="annualIncomeId" onChange={handleformdta} className="form-select">
                                                    <option value="">---Select---</option>
                                                    {
                                                        data?.annualIncome && data?.annualIncome.map((ag) => (
                                                            <option value={ag.id}>{ag.value}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="w-100 p-1 bg-gradient text-white">
                                        <EditOutlined />
                                        Address Details</div>
                                </div>




                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                Address Line 1
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="addressLine1" onChange={handleformdta} value={user?.addressLine1} id="" className="form-control" />
                                        </div>

                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                Address Line 2
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="addressLine2" onChange={handleformdta} value={user?.addressLine2} id="" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                Country Name
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="countryName" onChange={handleformdta} value={user?.countryName} id="" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                State Name
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="stateName" onChange={handleformdta} value={user?.stateName} id="" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                City Name
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="cityName" onChange={handleformdta} value={user?.cityName} id="" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="col-12">
                                            <label htmlFor="">
                                                pincode
                                            </label>
                                        </div>
                                        <div className="col-12">
                                            <input type="text" name="pincode" onChange={handleformdta} value={user?.pincode} id="" className="form-control" />
                                        </div>
                                    </div>

                                </div>


                                <div className="row">
                                    <div className="col-12 offset-md-4">
                                        <button onClick={updateProile} className="btn btn-flower">Update Details <EditFilled /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EditProfile
