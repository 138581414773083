
import PageBanner from 'layouts/PageBanner.jsx'
import React, { useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import check from "../../assets/images/check.jpg"
import { useNavigate } from 'react-router-dom';

function DeletePolicy() {

    const [show, setshow] = useState(false)
    const handleClose = () => setshow(false);
    const navigate = useNavigate()
    const handlesubmit = (e) => {
        e.preventDefault()
        setshow(true)
        setTimeout(() => {
            setshow(false)
            navigate('/')
        }, 1000);
    }

    return (
        <>

            <PageBanner title="Delete Policy" />
            <section className='py-5 policycontent'>

                <div className="container">

                    <Form onSubmit={(e) => handlesubmit(e)}>
                        <div className="row gy-3">
                            <div className="col-md-12">
                                <h3 className='text-center'>Delete Account Form</h3>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Name <span className='text-danger'>*</span></label>
                                <input type="text" className='form-control' placeholder='Name' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Email <span className='text-danger'>*</span></label>
                                <input type="text" className='form-control' placeholder='Email' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Phone </label>
                                <input type="text" placeholder='Phone' className='form-control' />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="">Reason <span className='text-danger'>*</span>  </label>
                                <textarea name="" required style={{ height: "150px" }} placeholder='Why Are You Delete Account ?' className='form-control' id=""></textarea>
                            </div>
                            <div className="col-md-12">
                                <button className='btn  text-white px-5' style={{ background: "#fb846f" }}>Submit</button>
                            </div>
                        </div>

                    </Form>
                </div>
            </section>



            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size='md'
                centered
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{"Account Deletion Confirmation"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="d-flex justify-content-center mb-4">
                            <img src={check} className="img-fluid" style={{ height: "100px", width: "100px" }} alt="Success" />
                        </div>
                        <h5 style={{ color: "#fb846f" }}>Your account will be deleted after 2 days.</h5>
                    </div>

                </Modal.Body>

            </Modal>


        </>
    )
}

export default DeletePolicy