import './App.css';
import ThemeRoutes from 'router';

function App() {


  return (
    <ThemeRoutes />
  );
}

export default App;
