import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'

function Refund() {
    return (
        <>
            <PageBanner title="Refund & Cancellations" />
            <section className='py-5 policycontent'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>1. Introduction</h3>
                                <p>At Suraj Sujan Matrimony, we are committed to providing high-quality matrimonial services to our users. This Non-Refundable Policy outlines the terms and conditions under which payments made for our services are non-refundable.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>2. Non-Refundable Policy</h3>
                                <p>All payments made for the services provided by Suraj Sujan Matrimony are final and non-refundable. This includes, but is not limited to, any fees paid for registration, profile enhancements, or any other service offered by our platform.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>3. Exceptions</h3>
                                <p>While we strive to ensure the satisfaction of our users, no exceptions to this non-refundable policy will be made, except under the following conditions:</p>
                                <ul>
                                    <li><strong>Duplicate Payments:</strong> In the event of a duplicate payment error, we will review the case and may issue a refund for the duplicate amount.</li>
                                    <li><strong>Technical Errors:</strong> If a technical error on our part results in an incorrect charge, we will investigate and may issue a refund.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>4. Contact Us</h3>
                                <p>If you have any questions or concerns regarding this Non-Refundable Policy, please contact our customer support at <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a>.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>5. Company Information</h3>

                                <p>Surajmal Memorial
                                    Education Society
                                    C-4, Janakpuri,
                                    New Delhi-58</p>
                                <p>Phone:011-45656183</p>
                                <p>Email: <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Refund