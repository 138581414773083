import React, { useEffect, useState } from 'react'

import Container from 'react-bootstrap/Container';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logoimg from 'assets/images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { navlist } from 'layouts/AuthLayout/Sidebar/Links';
import { Link, useLocation } from 'react-router-dom';


const Header = ({ isAuth }) => {
    const location = useLocation();

    const privacy = location.pathname == '/privacy-policy'
    const refund = location.pathname == '/refund-cancellation'
    const terms = location.pathname == '/terms-conditions'





    return (
        <>
            {(!privacy && !refund && !terms) ? <Navbar key={'md'} expand={'md'} className="bg-body-tertiary mb-0 surajmalNavbar">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <img src={logoimg} alt="" className="img-fluid navbarlogo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${'md'}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${'md'}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${'md'}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${'md'}`}>
                                Offcanvas
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1 pe-3">
                                <Link className='nav-link' to="/">Home</Link>
                                <Link className='nav-link' to="/about">About</Link>
                                <Link className='nav-link' to="/story">Story</Link>
                                <Link className='nav-link' to="/members">Members</Link>
                                {
                                    isAuth && (
                                        <>
                                            <NavDropdown
                                                className='p-0'
                                                title="Dashboard"
                                                id={`offcanvasNavbarDropdown-expand-${'md'}`}
                                                style={{ minWidth: "13rem" }}
                                            >
                                                {navlist}

                                            </NavDropdown>
                                        </>
                                    )
                                }

                            </Nav>
                            {
                                !isAuth && (
                                    <>
                                        <Nav className="d-inline-flex justify-content-end ">
                                            <Link className='nav-link' to="/login">
                                                <FontAwesomeIcon icon={faUserCircle} />
                                            </Link>
                                        </Nav>
                                    </>
                                )
                            }


                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar> :
                <Navbar key={'md'} expand={'md'} className="bg-body-tertiary mb-0 surajmalNavbar">
                    <Container fluid>
                        <Navbar.Brand href="/" className='text-center w-100'>
                            <img src={logoimg} alt="" className="img-fluid navbarlogo" />
                        </Navbar.Brand>


                    </Container>
                </Navbar>}
        </>
    )
}

export default Header
