import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'
import coupleimg from 'assets/images/couple.png'
import { Link } from 'react-router-dom'

const Dashboard = () => {
    return (
        <>
            <PageBanner title="Dashboard" />
            <section className="py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="w-100">
                                <h2>Welcome to Dashboard</h2>
                                <div className="w-100">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="w-100 p-3 statics_data">
                                                <h4>Total Members</h4>

                                                <h2>800+</h2>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="w-100 p-3 statics_data">
                                                <h4>Success Story</h4>

                                                <h2>100+</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 mt-4">
                                    <p>
                                    Matrimonialsindia.com is exclusively meant for marriage seekers and not for dating, casual relationships, commercial use, or illegal activities. Users must have a genuine intent for a matrimonial alliance.
                                    </p>
                                </div>
                                <div className="w-100 mt-4">
                                    <Link to={'/profiles'} className="btn btn-flower rounded-pill">Get Started</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="w-100">
                                <img src={coupleimg} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard
