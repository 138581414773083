import React, { useEffect } from 'react'
import banner from 'assets/images/page-banner.jpg'

const PageBanner = (props) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling animation
        });
    }

    useEffect(() => {
        scrollToTop();

    }, []);
    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="w-100 position-relative d-flex align-items-center " id="banner_page">
                                <img src={banner} alt="page banner" className="img-fluid w-100 z-1 position-absolute top-0 end-0 h-100" />
                                <div className="w-100 position-relative z-3">
                                    <h4 className="mb-0">{props.title}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PageBanner
