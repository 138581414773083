import PageBanner from 'layouts/PageBanner.jsx'
import React, { useEffect, useState } from 'react'
import profileimg from 'assets/images/p_img.png'
import { view_user_details } from 'utils'
import { EditOutlined, EyeOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useParams } from 'react-router-dom'

const Profile = () => {
    const params = useParams();
    const my_id = localStorage.getItem('muid');
    const mid = params.muid ?? localStorage.getItem('muid');
    const [user, setUser] = useState([]);
    const myprofile = async () => {
        const res = await view_user_details(mid);
        setUser(res.recordList[0])
    }
    useEffect(() => {
        myprofile();
    }, []);
    return (
        <>
            <PageBanner title="My Profile" />
            <section className="space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="w-100 my__profile">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="w-100 h-100">
                                            <figure className="profile_img">
                                                <img src={profileimg} alt="" className="img-fluid w-100 h-100 rounded-circle" />
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <h4>{user?.firstName ?? 'Loading...'} {user?.lastName}</h4>
                                        <p>{user?.gender}</p>
                                        <p>
                                            <span><EyeOutlined /></span> {user?.totalView}
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Religion
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.religion}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Father Gautra
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.fatherGotra}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Gautra Avoided
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.gotraAvoided}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Marital Status
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.maritalStatus}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Education
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.education}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Age
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.age} Years</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Date of Birth
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{moment(user?.birthDate).format('MMM DD, Y')}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Annual Income
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.annualIncome}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Address
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.addressLine1 + ' ' + user?.addressLine2 + ' ' + user?.cityName + ' ' + user?.pincode}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Occupation
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.occupation}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="">
                                            Languages
                                        </label>
                                    </div>
                                    <div className="col-8">
                                        <div className="w-100 label_value">{user?.languages}</div>

                                    </div>
                                </div>
                                {
                                    my_id == mid && (
                                        <>
                                            <div className="row">
                                                <div className="col-8 offset-md-4">
                                                    <a href='/edit-profile' className="btn btn-flower">Edit Details <EditOutlined /></a>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Profile
