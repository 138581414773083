import React from 'react'
import Slider from 'react-slick'
import quoteimg from '../../assets/images/quote.png'

const Testimonials = ({ bg }) => {
    var settings = {
        dots: false,

        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,

        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,

                }
            }
        ]
    };
    const items = [
        {
            image: "https://7xweddings.com/images/testimonials/aneri--ankush.jpg",
            content: `The Suraj Sujan Matrimonial Performa truly respects tradition while embracing modern matchmaking. The profiles were authentic and detailed, which helped us find exactly what we were looking for in a life partner. It's more than just a platform; it's a community dedicated to meaningful relationships.`
        },
        {
            image: "https://i.pinimg.com/474x/f2/5c/3d/f25c3d4b9525f5f4aa05cf1fc2069dd0.jpg",
            content: "We are so grateful to the Suraj Sujan Matrimonial Performa for bringing us together. The platform made it easy for our families to connect, and we found so much in common right from the beginning. The whole process was seamless and transparent, giving us the confidence to take the next step in our journey. Thank you for helping us find our happily-ever-after!"
        },
        {
            image: "https://i.pinimg.com/originals/22/32/05/223205e328f394bd29b4eb6e2b23c47f.jpg",
            content: "We couldn’t be happier with the experience we had on the Suraj Sujan Matrimonial Performa. From the thoughtful design of the platform to the quality of the connections we made, everything felt personalized and professional. We met through this platform and are now happily married!"
        }
    ];

    return (
        <>
            <section className={`space ${bg}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='section_label'>
                                What you say about us
                            </h2>
                        </div>
                        <div className="col-md-12">
                            <Slider {...settings}>
                                {
                                    items.map(itm => (
                                        <>


                                            <div className="w-100">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="tesimonial_image pe-3 position-relative">
                                                                <img src={itm.image} alt="" className="" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="w-100 position-relative">
                                                                <div className="w-100">
                                                                    <img src={quoteimg} alt="" className='upperquote' />
                                                                </div>
                                                                <div className="w-100">
                                                                    <p className="testimonialtext">
                                                                        {itm.content}
                                                                    </p>
                                                                </div>
                                                                <div className="w-100 text-end  ">
                                                                    <img src={quoteimg} alt="" className='lowerquote' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials
