import AuthWebLayout from "layouts/MainLayout.jsx/AuthWebLayout";
import NotFound from "NotFound";
import EditProfile from "pages/auth/EditProfile";
import Profile from "pages/auth/Profile";
import Myproposels from "pages/dashboard/Myproposels";
import Profiles from "pages/dashboard/Profiles";
import ProposelsToMe from "pages/dashboard/ProposelsToMe";
import SearchProfile from "pages/dashboard/SearchProfile";

const { default: Dashboard } = require("pages/dashboard");

const ProtectedRoutes = {
    path: "/",
    element: <AuthWebLayout />,
    children: [
        {
            path: "/dashboard",
            element: <Dashboard />
        },
        {
            path: "/profiles",
            element: <Profiles />
        },
        {
            path: "/proposels",
            element: <Myproposels />
        },
        {
            path: "/proposels-to-me",
            element: <ProposelsToMe />
        },
        {
            path: "/profile",
            element: <Profile />
        },
        {
            path: "/profile/:muid",
            element: <Profile />
        },
        {
            path: "/edit-profile",
            element: <EditProfile />
        },
        {
            path: "/search-profile",
            element: <SearchProfile />
        },
        {
            path: "*",
            element: <NotFound />
        }
    ]
}
export default ProtectedRoutes;