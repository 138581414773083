import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'
import Cta from 'pages/Home/Cta'
import Testimonials from './Testimonials'
import coupleimg from '../../assets/images/couple.jpg'
const About = () => {

  return (
    <>
      <PageBanner title="About Us" />
      <section className='space'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className='section_label'>
                About Us
              </h2>
            </div>

            <div className="col-md-12">
              <div className="w-100 aboutSectionPage text-justify">
                <p className='float-start'>
                  <img src={coupleimg} alt="" className="img-fluid aboutusimg rounded-4" />
                  The Suraj Sujan Matrimonial Performa, an initiative by the Surajmal Memorial Education Society, is a dedicated platform designed to foster meaningful and compatible connections within the community. Rooted in the values of trust, integrity, and tradition, we aim to bridge the gap between individuals and families seeking matrimonial alliances

                  Our platform provides a user-friendly and secure environment where profiles are carefully curated to ensure authenticity and relevance. Whether you are looking for a partner who aligns with your values, aspirations, or cultural preferences, the Suraj Sujan Matrimonial Performa is committed to helping you take the first step toward a lifelong journey of companionship.

                  At the core of this initiative lies our dedication to upholding societal values while embracing the evolving needs of the modern world. We believe that a harmonious partnership is built on shared understanding and respect, and we strive to create opportunities for individuals to find their perfect match within a supportive and respectful framework.
                </p>
                <p>
                  Join us today and take a step toward finding a partner who complements your journey in life. With the Suraj Sujan Matrimonial Performa, let’s create bonds that last a lifetime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials bg={'bg-theme-light'} />
      <Cta />
    </>
  )
}

export default About
