import { faFingerprint, faHands, faShield, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Features = () => {
    return (
        <>
            <section className='space '>
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className='section_label'>
                        Find your Special Someone
                        </h2>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="w-100 text-center featurebox">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faUsers} />
                                            </div>
                                            <h4>100%</h4>
                                            <p>
                                                Mobile-verified profiles
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="w-100 text-center featurebox">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faShield} />
                                            </div>
                                            <h4>100+</h4>
                                            <p>
                                               Couples Success Story
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="w-100 text-center featurebox">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faFingerprint} />
                                            </div>
                                            <h4>100%</h4>
                                            <p>
                                              Data Privacy
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="w-100 text-center featurebox">
                                            <div className="icon">
                                                <FontAwesomeIcon icon={faHands} />
                                            </div>
                                            <h4>23+</h4>
                                            <p>
                                                Years of Service
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features
