import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'

const NotFound = () => {
  return (
    <>
        <PageBanner title="Not Found" />
        <section className="">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-center">
                            Page Not Found
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default NotFound
