import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'

function PrivacyPolicy() {

    return (
        <>

            <PageBanner title="Privacy Policy" />
            <section className='py-5 policycontent bg-odd'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>1. Introduction</h3>
                                <p>This IT Policy outlines the standards, responsibilities, and procedures for the use of Information Technology resources at Suraj Sujan Matrimonial . The purpose of this policy is to ensure the security, integrity, and efficient use of our IT resources. </p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>2. Information We Collect</h3>
                                <p>We collect the following types of information:</p>
                                <ul>
                                    <li><strong>Personal Information:</strong> Name, email address, phone number, date of birth, gender, address, and other relevant details you provide during registration or profile updates.</li>
                                    <li><strong>Usage Data:</strong> Information about how you interact with our website, such as your IP address, browser type, and activity on the site.</li>
                                    <li><strong>Cookies and Tracking Technologies:</strong> We may use cookies to enhance your experience on our website.</li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-md-12">
                            <h3>3. How We Use Your Information</h3>
                            <p>We use your personal information to:</p>
                            <ul>
                                <li>Create and manage your account on Suraj Sujan Matrimony.</li>
                                <li>Facilitate matchmaking and matrimonial services.</li>
                                <li>Communicate with you about updates, offers, and services.</li>
                                <li>Improve our website's functionality and user experience.</li>
                                <li>Comply with legal obligations.</li>
                            </ul>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>4. Sharing Your Information</h3>
                                <p>We do not sell or share your personal information with third parties for marketing purposes. We may share your information with:</p>
                                <ul>
                                    <li><strong>Service Providers:</strong> To help us provide and improve our services.</li>
                                    <li><strong>Legal Authorities:</strong> If required by law or to protect our rights and the safety of others.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>5. Data Security</h3>
                                <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>6. Your Rights</h3>
                                <p>You have the right to:</p>
                                <ul>
                                    <li>Access, update, or delete your personal information.</li>
                                    <li>Opt-out of marketing communications.</li>
                                    <li>Request a copy of the data we hold about you.</li>
                                </ul>
                                <p>To exercise these rights, please contact us at <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a>.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>7. Data Retention</h3>
                                <p>We retain your personal information for as long as necessary to provide our services and comply with legal obligations. You may request deletion of your data by contacting us.</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h3>8. Contact Us</h3>
                            <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>

                            <p>
                                Surajmal Memorial
                                Education Society
                                C-4, Janakpuri,
                                New Delhi-58
                            </p>
                            <p> Phone:  011-45656183</p>
                            <p>Email: <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a></p>

                        </div>
                        <div className="col-md-12">
                            <div className="w-100">
                                <h3>9. Changes to This Privacy Policy</h3>
                                <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will take effect upon posting.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>



    )
}

export default PrivacyPolicy