import React from 'react'
import Header from './Header'
import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom';
const AuthWebLayout = () => {
    const [isAuth, setAuth] = React.useState(false);

    const navigate = useNavigate();

    const checkAuth = () => {
        const token = localStorage.getItem('mtoken') ?? false;
        if (!token) {
            return navigate('/login');
        }
        setAuth(true);
    }
    React.useEffect(() => {
        checkAuth();
    }, []);
    return (
        <>
            <Header isAuth={isAuth} />
            <Outlet />
            <Footer />
        </>
    )
}

export default AuthWebLayout
