import React from 'react'
import goolgestore from 'assets/images/google-play.png'
import { Link } from 'react-router-dom'
import ctabg from 'assets/images/cta-bg.png'
import screen4 from "assets/images/couples/screen4.jpeg"

const Cta = () => {
    return (
        <>
            <section className="py-5 cta position-relative" id="cta">
                <div className="bg-overlay-gradiant"></div>

                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="py-5">
                                <h1 className="display-4 ">Start your search for a perfect match</h1>
                                <p className="text-dark mt-3 f-18">Find grooms and brides on Surajsujan Matrimony from all over India and other countries.</p>
                                <div className="d-flex mt-4 ">
                                    <div className="googleplay">
                                        <Link to="/" className='btn btn-custom'>
                                            <div className="w-100 px-4 py-2"><img src={goolgestore} alt="" className="img-fluid" /></div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-none d-lg-block">
                            <div className="cta-phone-image " style={{ right: "16%" }}>
                                <img src={screen4} style={{ height: "400px" }} alt="" className=" img-fluid rounded-3" />
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default Cta
