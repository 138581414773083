import React from 'react'
import Testimonials from './Testimonials'

const Stories = () => {
    return (
        <>
            <Testimonials bg={'bg-white'} />
        </>
    )
}

export default Stories
